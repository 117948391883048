<template>
  <div className="animated fadeIn">

      <div class="row mb-2">
        <div class="col-9">
          <h2 class="pb-0 mb-0">{{the_table_name}}</h2>
        </div>
        <div class="col-3">
          <button @click="downloadCSV($route.params.id)" class="btn btn-md btn-outline-dark" >Export</button>
          <b-button variant="outline-light btn-sm"  class="float-right" :to="{ name: 'Tables', params: { product: $route.params.product, insurer: $route.params.insurer } }">Back</b-button>
        </div>
      </div>

      <v-server-table :columns="the_columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

          <template slot="active" slot-scope="props">
            <i v-if="props.row.active === true || props.row.active === 'true'" class="fas fa-check text-success"></i>
            <i v-else class="fas fa-times text-danger"></i>
          </template>

          <template slot="actions" slot-scope="props">
            <b-button variant="secondary" :to="{ name: 'TableRate', params: { product: $route.params.product, insurer: $route.params.insurer, id: $route.params.id, rateid: props.row.id } }">View</b-button>
          </template>

      </v-server-table>

  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../../shared/axios/auth'

  Vue.use(ServerTable)

  export default {
    name: 'DataTable',
    components: {
      ServerTable
    },
    data: function () {
      return {
        table_name: null,
        columns: [],
        data: [],
        fields: [],
        options: {
          requestFunction: (data) => {
              return axios.get('/tables/' + this.$route.params.id + '/rates/?product=' + this.$route.params.product + '&insurer=' + this.$route.params.insurer, {
                  params: data
              }).then((response) => {

                this.table_name = response.data.table_name
                this.columns = response.data.columns
                this.options.headings = response.data.headings

                return response

              }).catch(error => {
                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('quotes error');
                console.log(error.response)
              })
          },
          responseAdapter: function(resp) {
            var data = this.getResponseData(resp);
            return { data: data.data.data, count: data.data.pagination.total}
          },
          headings: {},
          filterByColumn: false,
          sortable: [],
          filterable: [
          ],
          listColumns: {
            area: [
              {
                id: 1,
                text: '1'
              },
              {
                id: 2,
                text: '2'
              }

            ]
          },
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    computed: {
      the_table_name() {
        return this.table_name
      },
      the_columns() {
        return this.columns
      },
    },
    methods: {
      downloadCSV(table_id) {
        this.get_document = true;

        axios({
          url: '/tables/' + table_id +'/export/',
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          console.log(response)
          console.log(response.file_name)
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.the_table_name + ".csv");
          document.body.appendChild(link);
          link.click();
          this.get_document = false;
        }).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
      },
    }

  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }
  .form-inline label {
      justify-content: left;

  }


</style>
